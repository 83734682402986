<template>
  <div class="competition-offering-priorities-single-root">
    <div class="offering-header">
      <img
        :src="`/images/icons/${feed}.png`"
        alt="feed-icon"
      >
      <p>{{ feed }}</p>
    </div>
    <div class="offering-priority">
      <div class="priority-select">
        <div class="offering-priority-title">
          Priority
        </div>
        <Select
          :model-value="selectedPriority"
          :options="priorityOptions"
          :variant="'secondary'"
          :placeholder="' '"
          :omit-prefix="true"
          @update:model-value="updatePriority"
        />
      </div>
      <div class="priority-toggle">
        <Toggle
          :model-value="offeringConfig?.coverageExpected"
          @update:model-value="toggleOverridePriority"
        />
        <p>Override</p>
      </div>
    </div>
    <div class="offering-market-categories">
      <div class="market-categories-title">
        Offering
      </div>
      <div
        v-for="category in marketCategories"
        :key="category.value"
        class="market-categories-item"
        @click="toggleMarketCategory(category.value)"
      >
        <Checkbox
          :model-value="isMarketCategorySelected(category.value)"
          @update:modelValue="toggleMarketCategory(category.value)"
        />
        <p>{{ category.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { includes, filter } from 'lodash';
import Select from '@/components/common/Select';
import Toggle from '@/components/common/Toggle';
import Checkbox from '@/components/common/Checkbox';

export default {
  emits: ['update:offeringConfig'],
  props: {
    offeringConfig: {
      type: Object,
      required: true,
    },
  },
  components: {
    Select,
    Toggle,
    Checkbox,
  },
  setup(props, { emit }) {
    const feed = computed(() => props.offeringConfig?.feed);
    const priorityOptions = computed(() => [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
    ]);
    const selectedPriority = computed(() => props.offeringConfig?.priority);

    const marketCategories = computed(() => [
      { value: 'CORE_AND_DERIVATIVES', label: 'Core & derivatives' },
      { value: 'SGP', label: 'Single game parlay' },
      { value: 'PLAYER_PROPS', label: 'Player props' },
      { value: 'MICROS', label: 'Micro markets' },
    ]);

    const isMarketCategorySelected = (category) => includes(props.offeringConfig?.marketCategories, category);

    const updatePriority = (updatedValue) => {
      emit('update:offeringConfig', {
        ...props.offeringConfig,
        priority: updatedValue,
      });
    };

    const toggleOverridePriority = (updatedValue) => {
      emit('update:offeringConfig', {
        ...props.offeringConfig,
        coverageExpected: updatedValue,
      });
    };

    const toggleMarketCategory = (category) => {
      let updatedMarketCategories;
      if (isMarketCategorySelected(category)) {
        updatedMarketCategories = filter(props.offeringConfig.marketCategories, (c) => c !== category);
      } else {
        updatedMarketCategories = [...props.offeringConfig.marketCategories, category];
      }
      emit('update:offeringConfig', {
        ...props.offeringConfig,
        marketCategories: updatedMarketCategories,
      });
    };

    return {
      feed,
      priorityOptions,
      selectedPriority,
      marketCategories,
      isMarketCategorySelected,
      updatePriority,
      toggleOverridePriority,
      toggleMarketCategory,
    };
  },
};
</script>

<style lang="scss">
.competition-offering-priorities-single-root {
  .offering-header {
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 12px;
    font-weight: 600;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .offering-priority {
    display: flex;
    align-items: center;
    gap: 16px;
    .priority-select {
      .offering-priority-title {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray800;
        margin-bottom: 2px;
      }
      .select {
        width: 56px;
      }
    }
    .priority-toggle {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 16px;
    }
  }
  .offering-market-categories {
    margin-top: 16px;
    .market-categories-title {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      color: $gray800;
      margin-bottom: 8px;
    }
    .market-categories-item {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
}
</style>
