import {
  map,
  intersection,
  sortBy,
  reduce,
  flatMap,
} from 'lodash';
import {
  fetchEligibleSports,
  fetchPrimarySportFeeds,
  fetchSportCompetitionConfigurations,
} from '@/services/api';
import { feedsOrder } from '@/services/helpers/mappings-mapper';

export const marketCategoryTypes = {
  CORE_AND_DERIVATIVES: 'CORE_AND_DERIVATIVES',
  SGP: 'SGP',
  PLAYER_PROPS: 'PLAYER_PROPS',
  MICROS: 'MICROS',
};

export const allMarketCategories = {
  [marketCategoryTypes.CORE_AND_DERIVATIVES]: {
    id: marketCategoryTypes.CORE_AND_DERIVATIVES,
    name: 'Core and derivatives',
    shortName: 'C&D',
    order: 1,
  },
  [marketCategoryTypes.SGP]: {
    id: marketCategoryTypes.SGP,
    name: 'Same game parlay',
    shortName: 'SGP',
    order: 2,
  },
  [marketCategoryTypes.PLAYER_PROPS]: {
    id: marketCategoryTypes.PLAYER_PROPS,
    name: 'Player Props',
    shortName: 'PP',
    order: 3,
  },
  [marketCategoryTypes.MICROS]: {
    id: marketCategoryTypes.MICROS,
    name: 'Micro markets',
    shortName: 'MM',
    order: 4,
  },
};

export const loadSports = async () => {
  const response = await fetchEligibleSports();
  return map(response.data?.sports?.nodes || [], (sport) => ({
    id: sport.sportId,
    name: sport.sportName,
  }));
};

export const loadFeeds = async (sportId) => {
  const response = await fetchPrimarySportFeeds(sportId);
  const primaryFeeds = map(response.data?.allPrimaryFeeds?.nodes || [], ({ feed }) => feed);
  const sportFeeds = map(response.data?.allSportFeeds?.nodes || [], ({ feed }) => feed);
  const applicableFeeds = intersection(primaryFeeds, sportFeeds);
  return sortBy(applicableFeeds, (feed) => feedsOrder[feed]);
};

export const loadCompetitions = async ({
  sportId,
  search,
  order,
  first,
  offset,
}) => {
  const response = await fetchSportCompetitionConfigurations({
    sportId,
    search,
    order,
    first,
    offset,
  });
  const mappedResponse = reduce(
    response.data?.competitions?.nodes,
    (result, composite) => {
      const competitions = [
        ...result.competitions,
        {
          competitionId: composite.competitionId,
          competitionName: composite.competitionName,
          regionId: composite.region?.regionId,
          regionName: composite.region?.regionName,
          ageCategory: composite.ageCategory,
          gender: composite.gender,
          defaultRules: composite.defaultRules,
          feedConfiguration: composite.feedConfiguration,
        },
      ];
      const configurations = {
        ...result.configurations,
        ...reduce(
          composite.feedConfiguration?.nodes || [],
          (configResult, config) => ({
            ...configResult,
            [`${composite.competitionId}:${config.feed}`]: {
              ...config,
              id: `${composite.competitionId}:${config.feed}`,
              competitionId: composite.competitionId,
              competitionName: composite.competitionName,
            },
          }),
          {},
        ),
      };
      return {
        competitions,
        configurations,
      };
    },
    {
      competitions: [],
      configurations: {},
    },
  );
  return {
    totalCompetitionsCount: response.data?.competitions?.totalCount || 0,
    competitions: mappedResponse.competitions,
    configurations: mappedResponse.configurations,
  };
};

export const makeFeedColumns = (feeds) => flatMap(feeds, (feed) => [
  {
    id: `priority-${feed}`,
    type: 'priority',
    label: 'Priority',
    feed,
  },
  {
    id: `configuration-${feed}`,
    type: 'configuration',
    label: 'Configuration',
    feed,
  },
]);

export const sortMarketCategoryCodes = (marketCategoryCodes) => sortBy(marketCategoryCodes, (code) => allMarketCategories[code].order);
